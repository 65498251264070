<div class="w-100">
    
    <div class="banner">
        <div class="mb-3 language">
     <button class="btn btn-outline-secondary mr-2" (click)="switchLanguage('en')">{{ 'home.english' | translate }}</button>
    <button class="btn btn-outline-secondary" (click)="switchLanguage('es')">{{ 'home.español' | translate }}</button>
       </div>
    <div class="text">
            <p class="text-left">{{ 'home.hola' | translate }}</p>
                
            <h1 class="text-warning">{{ 'home.diseno' | translate }}</h1>
            
            <h6>{{ 'home.gusto' | translate }}
            </h6>
            <hr class="bg-light">

            <div class="d-flex justify-content-between info">
                <div class="col-lg-6">
                    <p data-toggle="modal" data-target="#cursos" style="cursor: pointer;"> 
                        <span class="material-icons" title="Estudios">school</span> 
                        UNITEC
                       <br>
                       <span  class="material-icons mr-1 mt-1" title="Cursos">cast_for_education</span>{{ 'home.cursos' | translate }}
                    </p> 
                    
                <!-- <p> <span class="material-icons" title="Último empleo">work</span> Honeywell México 
                 </p>  -->
                </div>               

                <div class="col-lg-6">
                    <p> 
                        <span class="material-icons" title="Empleo actual">location_on</span> 
                        {{ 'home.mexico' | translate }}
                    </p> 
                </div>

            </div>

            <div class="d-flex knowledge">
           <div class="col-lg-3 col-xs-12"> 
                <h6 class="text-warning mt-4"> <small class="material-icons align-middle" title="Empleo actual">developer_mode</small> Front-end</h6>
                <div class="d-block text-light">
                   <ul>
                       <li>HTML</li>
                       <li>SCSS</li>
                       <li>Bootstrap</li>
                       <li>Javascript</li>
                       <li>JQuery</li>
                       <li>Angular</li>
                       <li>React</li>
                       <li>Blazor</li>
                   </ul>
                </div>
            </div>

            <div class="col-lg-8 col-xs-12"> 
                <div class="d-flex justify-content-around knowledge">
                        <div class="d-block mr-3">
                                <h6 class="text-warning mt-4"> <small class="material-icons align-middle">palette</small> {{ 'home.disenoSoft' | translate }}</h6>
                                <div class="d-block text-light">
                                <ul>
                                    <li>Photoshop</li>
                                    <li>Illustrator</li>
                                    <li>Adobe XD</li>       
                                    <li>Figma</li>                 
                                </ul>
                                </div>
                        </div>

                        <div class="d-block programs">
                        <h6 class="text-warning mt-4"> <small class="material-icons align-middle">dashboard</small>{{ 'home.programs' | translate }}</h6>
                        <div class="d-block text-light">
                        <ul>
                            <li>Visual Studio</li>
                            <li>Visual Studio Code</li>
                            <li>Office</li>                     
                        </ul>
                        </div>
                        </div>
                </div>
                <div class="w-100 ml-3">
                    <h6 class="text-warning mt-4">
                        <small class="material-icons align-middle mr-2">desktop_mac</small>{{ 'home.systems' | translate }}</h6>
                <img src="/assets/images/apple.png" class="mr-5" width="50" alt="IOS">
                <img src="/assets/images/microsoft.png" width="50" alt="Windows">
                </div>
            </div>
         </div>
        </div>
   </div>
   
</div>

<div class="work d-flex justify-content-between">
        <div class="book web" [routerLink]="['/web']">
           <span class="material-icons">phonelink</span>
           <h2>WEB</h2>
            <div class="description">
                <p>{{ 'home.blockweb' | translate }}
                </p>
                <hr class="bg-light mt-3"/>
                <div class="w-100 text-right">
                    <button type="button" class="more btn btn-outline-light"> 
                        <small> {{ 'home.mas' | translate }} <i class="material-icons align-middle">keyboard_arrow_right</i></small>
                    </button>
                </div>
            </div>
        </div>
        <div class="book logo" [routerLink]="['/logos']">
            <span class="material-icons">format_shapes</span>
            <h2>{{ 'menu.logo' | translate }}</h2>
            <div class="description">
                <p>{{ 'home.blocklogo' | translate }}
                </p>
                <hr class="bg-light mt-3"/>
                <div class="w-100 text-right">
                    <button type="button" class="more btn btn-outline-light"> 
                        <small> {{ 'home.mas' | translate }} <i class="material-icons align-middle">keyboard_arrow_right</i></small>
                    </button>
                </div>
            </div>
           
        </div>
        <div class="book foto" [routerLink]="['/fotografia']">
            <span class="material-icons">camera_alt</span>
           <h2>{{ 'menu.foto' | translate }}</h2>
           <div class="description">
            <p>{{ 'home.blockfoto' | translate }}
            </p>
            <hr class="bg-light mt-3"/>
            <div class="w-100 text-right">
                <button type="button" class="more btn btn-outline-light"> 
                    <small> {{ 'home.mas' | translate }} <i class="material-icons align-middle">keyboard_arrow_right</i></small>
                </button>
            </div>
        </div>
        </div>
        <div class="book history" [routerLink]="['/historia']">
            <span class="material-icons">timeline</span>
            <h2>{{ 'menu.historia' | translate }}</h2>
            <div class="description">
                <p>{{ 'home.blockhistory' | translate }}
                </p>
                <hr class="bg-light mt-3"/>
                <div class="w-100 text-right">
                    <button type="button" class="more btn btn-outline-light" [routerLink]="['/historia']"> 
                        <small> {{ 'home.mas' | translate }} <i class="material-icons align-middle">keyboard_arrow_right</i></small>
                    </button>
                </div>
            </div>
            
        </div>

     
</div>

<div class="modal fade" id="cursos" tabindex="-1" aria-labelledby="cursos" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="cursos">
          <small><span class="material-icons mr-3" style="font-size: 30px">cast_for_education</span></small>{{ 'home.cursos2' | translate }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <h6 class="text-info">UNITEC- 2011 & 2014</h6>
            <p>{{ 'educacion.unitec' | translate }}</p>

            <h6 class="text-info">EDUMAC- 2014 & 2018</h6>
            <p>{{ 'educacion.edumac' | translate }}</p>

            <h6 class="text-info">SIMPLE WIRE- 2017</h6>
            <p>{{ 'educacion.simple' | translate }}</p>

            <h6 class="text-info">UDEMY- 2018</h6>
            <p>{{ 'educacion.udemyux' | translate }}</p>

            <h6 class="text-info">UDEMY- 2019</h6>
            <p>{{ 'educacion.udemyangular' | translate }}</p>

            <h6 class="text-info">MICROSOFT EXAM CERTIFICATION PATH- 2019</h6>
            <p>{{ 'educacion.msft' | translate }}</p>

            <h6 class="text-info">Wes Bos- 2023</h6>
            <p>{{ 'educacion.udemyreact' | translate }}</p>

            <h6 class="text-info">SCALED AGILE - 2023</h6>
            <p>{{ 'educacion.safe' | translate }}</p>

            <h6 class="text-info">Product Security Advocate - 2023</h6>
           
        </div>
        
      </div>
    </div>
  </div>