import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-caem',
  templateUrl: './caem.component.html',
  styleUrls: ['./caem.component.css']
})
export class CaemComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
