
<div class="contenedor d-flex">
<app-menu></app-menu>
        <div class="image col-lg-4 col-xs-12 pt-5">
            <h2>
            <i>{{'logos.title'| translate}}</i>
            </h2>
            <hr>
            <p class="text-dark">{{'logos.subtitle' | translate}}</p>   
        </div> 


   
    <div class="col-lg-8 col-xs-12 logos">
    
            <div class="row">
            <div class="box col-lg-6 col-xs-12">
            <div class="box-inner">
            <div class="box-front">
            <img class="img-fluid align-middle" src="/assets/images/portafolio/logos/natskin.jpg" alt="Natskin">
            </div>
            <div class="box-back">
            <h2 class="orange">NATSKIN</h2>
            <p class="text-secondary">
                {{'logos.natskin' | translate}}
            </p>
            </div>
        </div>
            </div>
        
            <div class="box col-lg-6 col-xs-12">
                <div class="box-inner">
                <div class="box-front">
                    <img class="img-fluid align-middle" src="/assets/images/portafolio/logos/logoTlaloc.svg" alt="CAEM APP">
                </div>
                <div class="box-back">
                    <h2 class="blue">CAEM APP</h2>
                    <p class="text-secondary">
                    {{'logos.tlaloc' | translate}}
                    </p>
                </div>
                </div>
            </div>
            </div>

            <div class="row mt-2">
            <div class="box col-lg-6 col-xs-12">
                <div class="box-inner">
                <div class="box-front">
                    <img class="img-fluid align-middle mt-5" src="/assets/images/portafolio/logos/logoSegubroder.png" alt="Segubroder">
                </div>
                <div class="box-back">
                    <h2 class="green">SEGUBRODER</h2>
                    <p class="text-secondary">
                    {{'logos.segubroder' | translate}}
                    </p>
                </div>
                </div>
            </div>

            <div class="box col-lg-6 col-xs-12">
                <div class="box-inner">
                <div class="box-front">
                    <img class="img-fluid align-middle pt-5" src="/assets/images/portafolio/logos/logoTemasili.svg" alt="Temasili">
                </div>
                <div class="box-back">
                    <h2 class="pink">TEMASILI</h2>
                    <p class="text-secondary">
                    {{'logos.temasili' | translate}}
                    </p>
                </div>
                </div>
            </div>
            </div>

            <div class="row mt-2">
                <div class="box col-lg-6 col-xs-12">
                    <div class="box-inner">
                    <div class="box-front">
                        <img class="img-fluid align-middle mt-5" src="/assets/images/portafolio/logos/logotipoJacha.svg" alt="Grupo Jacha">
                    </div>
                    <div class="box-back">
                        <h2 class="orange">GRUPO JACHA</h2>
                        <p class="text-secondary">
                        {{'logos.jacha' | translate}}
                        </p>
                    </div>
                    </div>
                </div>
    
                <div class="box col-lg-6 col-xs-12">
                    <div class="box-inner">
                    <div class="box-front">
                        <img class="img-fluid align-middle pt-3" src="/assets/images/portafolio/logos/luckypals.jpg" alt="Lucky pals">
                    </div>
                    <div class="box-back">
                        <h2 class="green">LUCKY PALS</h2>
                        <p class="text-secondary">
                        {{'logos.lucky' | translate}}
                        </p>
                    </div>
                    </div>
                </div>
                </div>
    </div>
</div>