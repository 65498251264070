
<app-menu></app-menu>

<div class="row pt-5">
    <div class="col-lg-6 col-xs-12 pt-2 logo text-center">
        <img src="/assets/images/portafolio/caem/logoTlaloc.svg" width="150" alt="">
    </div>
    <div class="col-lg-4 col-xs-12 text">
        <p>{{'caem.descripcion' | translate}}</p>
    </div>
</div>

<hr/>

<div class="row bg-light">
    <div class="col-lg-6 col-xs-12 text-center mb-2">
        <img src="/assets/images/portafolio/caem/desktop1.jpg" alt="Home" class="img-fluid">
    </div>

    <div class="col-lg-6 col-xs-12 text-center mb-2">
        <img src="/assets/images/portafolio/caem/desktop2.jpg" alt="Home" class="img-fluid">
    </div>
</div>

<div class="row bg-light mt-2">
    <div class="col-lg-4 col-xs-12 text-center mb-2">
        <img src="/assets/images/portafolio/caem/app.jpg" alt="Home" height="600">
    </div>
    
    <div class="col-lg-4 col-xs-12 text-center mb-2">
        <img src="/assets/images/portafolio/caem/app2.jpg" alt="Home" height="600">
    </div>

    <div class="col-lg-4 col-xs-12 text-center mb-2">
        <img src="/assets/images/portafolio/caem/app3.jpg" alt="Home" height="600">
    </div>
</div>

