<app-menu></app-menu>
   
<div class="container-fluid">
<div class="presentation text-warning">
    {{'ux.welcome' | translate}}
</div>
<div class="subtitle">
<p class="text-light">{{'ux.subtitle' | translate}}</p>
</div>
<div>
    <div class="fila">
        
        <div class="block" data-aos="flip-up" [routerLink]="['/segubroder']">
            <img src="/assets/images/portafolio/segubroder.png">
        </div>
        <div class="block" data-aos="flip-up" [routerLink]="['/conegte']">  
            <img src="/assets/images/portafolio/conegteTi.png">
        </div>
        <div class="block" [routerLink]="['/medical']">
            <img src="/assets/images/portafolio/renal.png">
        </div>
    </div>
    <div class="fila">
        <div class="block" data-aos="flip-up" [routerLink]="['/temasili']">
            <img src="/assets/images/portafolio/temasili.png">
        </div>
        <div class="block" data-aos="flip-up" [routerLink]="['/global-quartz']">
            <img src="/assets/images/portafolio/global.png">
        </div>
        <div class="block" data-aos="flip-up" [routerLink]="['/caem']">
            <img src="/assets/images/portafolio/caem.png">
        </div>
        <div class="block" data-aos="flip-up" [routerLink]="['/elektra']">
            <img src="/assets/images/portafolio/elektra.png">
        </div>
    </div>
</div>
</div>
