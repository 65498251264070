import { Routes, RouterModule} from '@angular/router';
import { HomeComponent } from './src/app/home/home.component';
import { HistoriaComponent } from 'src/app/historia/historia.component';
import { UxComponent } from 'src/app/ux/ux.component';
import { SegubroderComponent } from 'src/app/segubroder/segubroder.component';
import { ConegteComponent } from 'src/app/conegte/conegte.component';
import { MedicalComponent } from 'src/app/medical/medical.component';
import { TemasiliComponent } from 'src/app/temasili/temasili.component';
import { GlobalQuartzComponent } from 'src/app/global-quartz/global-quartz.component';
import { CaemComponent } from 'src/app/caem/caem.component';
import { ElektraComponent } from 'src/app/elektra/elektra.component';
import { LogosComponent } from 'src/app/logos/logos.component';
import { FotoComponent } from 'src/app/foto/foto.component';

const app_routes: Routes = [
    {path: 'home', component: HomeComponent},
    {path: 'web', component: UxComponent},
    {path: 'segubroder', component: SegubroderComponent},
    {path: 'conegte', component: ConegteComponent}, 
    {path: 'medical', component: MedicalComponent}, 
    {path: 'temasili', component: TemasiliComponent}, 
    {path: 'global-quartz', component: GlobalQuartzComponent},   
    {path: 'caem', component: CaemComponent},
    {path: 'elektra', component: ElektraComponent},
    {path: 'logos', component: LogosComponent},
    {path: 'fotografia', component: FotoComponent},
    {path: 'historia', component: HistoriaComponent},     
    {path: '**', pathMatch: 'full', redirectTo:'home'}
];

export const APP_ROUTES = RouterModule.forRoot(app_routes, {useHash: true});
// export const APP_ROUTES = RouterModule.forRoot(app_routes);