<app-menu></app-menu>


<div class="timeline-container" id="timeline-1">
    <div class="timeline-header">
        <h2 class="timeline-header__title">{{'menu.historia' | translate}}</h2>
        <h3 class="timeline-header__subtitle">{{'home.blockhistory' | translate}}</h3>
    </div>
    <div class="timeline">
        <div class="timeline-item" data-text="Honeywell">
            <div class="timeline__content"><img class="timeline__img" src="/assets/images/empleos/HonLogo.png" />
                <h2 class="timeline__content-title">{{'historia.honeywellFechas' | translate}}</h2>
                <h6 class="timeline__content-desc"><b>{{'historia.puestoHoneywell' | translate}}</b></h6>
                    <hr class="bg-white">
                <p class="timeline__content-desc">
                    {{'historia.honeywelldescripcion' | translate}}
                 </p>
            </div>
        </div>
        <div class="timeline-item" data-text="RGOV DE MÉXICO">
            <div class="timeline__content"><img class="timeline__img" src="/assets/images/empleos/rgov.svg" />
                <h2 class="timeline__content-title">{{'historia.fechasrgov' | translate}}</h2>
                <h6 class="timeline__content-desc"><b>{{'historia.puestorgov' | translate}}</b></h6>
                    <hr class="bg-white">
                <p class="timeline__content-desc">
                    {{'historia.rgovdescripcion' | translate}}
                 </p>
            </div>
        </div>
        <div class="timeline-item" data-text="ELEKTRA E-COMMERCE">
            <div class="timeline__content"><img class="timeline__img" src="/assets/images/empleos/elektra.svg" />
                <h2 class="timeline__content-title">{{'historia.elektrafechas' | translate}}</h2>
                <h6 class="timeline__content-desc"><b>{{'historia.elektrapuesto' | translate}}</b></h6>
                    <hr class="bg-white">
                <p class="timeline__content-desc">
                    {{'historia.elektradescripcion' | translate}}
                </p>
            </div>
        </div>
        <div class="timeline-item" data-text="Axentit Business Consulting">
            <div class="timeline__content"><img class="timeline__img" src="/assets/images/empleos/axentit.png" />
                <h2 class="timeline__content-title">{{'historia.axentitpuesto' | translate}}</h2>
                <h6 class="timeline__content-desc"><b>{{'historia.axentitpuesto' | translate}}</b></h6>
                <p class="timeline__content-desc">
                    {{'historia.axentitdescripcion' | translate}}
                </p>
            </div>
        </div>
        
        <div class="timeline-item" data-text="DIF CDMX">
            <div class="timeline__content"><img class="timeline__img" src="/assets/images/empleos/dif.png" />
                <h2 class="timeline__content-title"> {{'historia.diffechas' | translate}}</h2>
                <h6 class="timeline__content-desc"><b> {{'historia.difpuesto' | translate}}</b></h6>
                <p class="timeline__content-desc">
                    {{'historia.difdescripcion' | translate}}
                </p>
            </div>
        </div>
        <div class="timeline-item" data-text="Hertz-Avasa">
            <div class="timeline__content"><img class="timeline__img" src="/assets/images/empleos/hertz.svg" />
                <h2 class="timeline__content-title">{{'historia.hertzdescripcion' | translate}}</h2>
                <h6 class="timeline__content-desc"><b>{{'historia.hertzpuesto' | translate}}</b></h6>
                <p class="timeline__content-desc">
                    {{'historia.hertzdescripcion' | translate}}
                </p>
            </div>
        </div>
    </div>
</div>
