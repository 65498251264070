<app-menu></app-menu>

    <div class="row pt-5">
        <div class="col-lg-6 col-xs-12 pt-5 logo text-center">
            <img src="/assets/images/portafolio/conegte/logoConegte.svg" width="350" alt="">
        </div>
        <div class="col-lg-4 col-xs-12 text">
            <p>{{'conegte.descripcion' | translate}}</p>
            <p>{{'conegte.reto' | translate}}</p>
        </div>
    </div>

    <hr/>

    <div class="row bg-light">
        <div class="col-lg-4 col-xs-12">
            <img src="/assets/images/portafolio/conegte/home.png" alt="Home" class="img-fluid">
        </div>
        
        <div class="col-lg-4 col-xs-12">
                <img src="/assets/images/portafolio/conegte/landing.png" alt="Home" class="img-fluid">
                <img src="/assets/images/portafolio/conegte/equipo.png" alt="Home" class="img-fluid">
        </div>

        <div class="col-lg-4 col-xs-12">
            <img src="/assets/images/portafolio/conegte/checkout.png" alt="Home" class="img-fluid">
        </div>
    </div>
