    <app-menu></app-menu>

    <div class="row pt-5">
        <div class="col-lg-6 col-xs-12 pt-5 logo text-center">
            <img src="/assets/images/portafolio/segubroder/portada.png" alt="">
        </div>
        <div class="col-lg-4 col-xs-12 text">
            <p>{{'segubroder.descripcion' | translate}}</p>
            <p>{{'segubroder.reto' | translate}}</p>
        </div>
    </div>

    <hr/>

    <div class="row bg-light">
        <div class="col-lg-4 col-xs-12 offset-lg-3">
            <img src="/assets/images/portafolio/segubroder/home.png" alt="Home" class="img-fluid">
        </div>
        
        <div class="col-lg-3 col-xs-12">
                <img src="/assets/images/portafolio/segubroder/cotizador.png" alt="Home" class="img-fluid">
                <img src="/assets/images/portafolio/segubroder/checkout.png" alt="Home" class="img-fluid">
        </div>

        <div class="col-lg-5 col-xs-12 d-flex">
            <img src="/assets/images/portafolio/segubroder/info.png" alt="Home" class="img-fluid">
          
    </div>

    <div class="row bg-light offset-lg-1">
        <div class="col-lg-6 col-xs-12 text-center">
            <img src="/assets/images/portafolio/segubroder/mobile.png" alt="Home" height="600">
        </div>
        

        <div class="col-lg-6 col-xs-12 text-center">
            <img src="/assets/images/portafolio/segubroder/mobile2.png" alt="Home" height="600">
        </div>
    </div>