<app-menu></app-menu>

<div class="background">    
        <div class="title">
          <h1> {{'foto.title' | translate}}</h1>
        </div>

</div>
<hr>
<div class="grid-container mt-2">
  <div>
    <img class='grid-item grid-item-1' src='assets/images/portafolio/foto/bee.jpg' alt=''>
   
  </div>
  <div>
    <img class='grid-item grid-item-2' src='assets/images/portafolio/foto/tio.jpg' alt=''>
   
  </div>
  <div>
    <img class='grid-item grid-item-3' src='assets/images/portafolio/foto/PacoTeques1.jpg' alt=''>
   
  </div>
  <div>
    <img class='grid-item grid-item-4' src='assets/images/portafolio/foto/eyes.jpg' alt=''>
   
  </div>
  <div>
    <img class='grid-item grid-item-5' src='assets/images/portafolio/foto/natacion.jpg' alt=''>
   
  </div>
  <div>
    <img class='grid-item grid-item-6' src='assets/images/portafolio/foto/shoe.jpg' alt=''>
   
  </div>
  <div>
    <img class='grid-item grid-item-7' src='assets/images/portafolio/foto/paco.jpg' alt=''>
   
  </div>
  <div>
    <img class='grid-item grid-item-8' src='assets/images/portafolio/foto/patitas.jpg' alt=''>
   
  </div>
  <div>
    <img class='grid-item grid-item-9' src='assets/images/portafolio/foto/puntaCometa.jpg' alt=''>
   
  </div>
  <div>
    <img class='grid-item grid-item-10' src='assets/images/portafolio/foto/hair.jpg' alt=''>
   
  </div>
</div>


