

<div>
    <nav class="navbar">
        <div class="menu navbar-toggler" data-toggle="collapse" data-target="#menu">
            <span class="material-icons">menu</span>
        </div>
      
        <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button> -->
    </nav>
    <div class="collapse" id="menu">
      <div class="pt-4 pr-4 text-right">
        <h5 class="th4" data-toggle="collapse" data-target="#menu"><span class="material-icons">close</span></h5>
      </div> 
      <hr class="bg-white"/>

      <div class="pr-4 pt-4 pl-1 contenido">
        <ul>
            <li [routerLink]="['/home']"><span class="material-icons">home</span>Home</li>
            <li [routerLink]="['/web']"><span class="material-icons">phonelink</span>Web</li>
            <li [routerLink]="['/logos']"><span class="material-icons">format_shapes</span>{{'menu.logo' | translate}}</li>
            <li [routerLink]="['/fotografia']"><span class="material-icons">camera_alt</span>{{'menu.foto' | translate}}</li>
            <li [routerLink]="['/historia']"> <span class="material-icons">timeline</span>{{'menu.historia' | translate}}</li>
            <li> <a href="mailto:tamadel5353@gmail.com"><span class="material-icons">mail</span>{{'menu.contacto' | translate}}</a></li>
        </ul>    
      </div>
      
      <div class="mb-3 pl-3 language">
        <button class="btn btn-outline-secondary mr-2" (click)="switchLanguage('en')">{{ 'home.english' | translate }}</button>
       <button class="btn btn-outline-secondary" (click)="switchLanguage('es')">{{ 'home.español' | translate }}</button>
          </div>
    </div>
    
</div>