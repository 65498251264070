import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { APP_ROUTES } from 'app.routes';
import { HistoriaComponent } from './historia/historia.component';
import { MenuComponent } from './menu/menu.component';
import { UxComponent } from './ux/ux.component';


import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SegubroderComponent } from './segubroder/segubroder.component';
import { ConegteComponent } from './conegte/conegte.component';
import { MedicalComponent } from './medical/medical.component';
import { TemasiliComponent } from './temasili/temasili.component';
import { GlobalQuartzComponent} from './global-quartz/global-quartz.component';
import { CaemComponent } from './caem/caem.component';
import { ElektraComponent } from './elektra/elektra.component';
import { LogosComponent } from './logos/logos.component';
import { FotoComponent } from './foto/foto.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HistoriaComponent,
    MenuComponent,
    UxComponent,
    SegubroderComponent,
    ConegteComponent,
    MedicalComponent,
    TemasiliComponent,
    GlobalQuartzComponent,
    CaemComponent,
    ElektraComponent,
    LogosComponent,
    FotoComponent
  ],
  imports: [
    BrowserModule,
    APP_ROUTES,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
   
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http,'./assets/i18n/');
}