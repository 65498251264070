<app-menu></app-menu>

    <div class="row pt-5">
        <div class="col-lg-6 col-xs-12 pt-5 logo text-center">
            <img src="/assets/images/portafolio/medical/logoRenal.png" alt="">
        </div>
        <div class="col-lg-4 col-xs-12 text">
            <p>{{'medical.descripcion' | translate}}</p>
            <p>{{'medical.reto' | translate}}</p>
        </div>
    </div>

    <hr/>

    <div class="row bg-light">
        <div class="col-lg-4 col-xs-12">
            <img src="/assets/images/portafolio/medical/home.png" alt="Home" class="img-fluid">
        </div>
        
        <div class="col-lg-4 col-xs-12">
                <img src="/assets/images/portafolio/medical/protocolo.png" alt="Home" class="img-fluid">
                
        </div>

        <div class="col-lg-4 col-xs-12 text-center">
            <img src="/assets/images/portafolio/medical/mobiledoc.png" alt="Home" height="550">
        </div>
    </div>