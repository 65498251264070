<app-menu></app-menu>

<div class="row pt-5">
    <div class="col-lg-6 col-xs-12 pt-5 logo text-center">
        <img src="/assets/images/portafolio/elektra/elektra.svg" style="filter:none" width="350" alt="">
    </div>
    <div class="col-lg-4 col-xs-12 text">
        <p>{{'elektra.descripcion' | translate}}</p>
    </div>
</div>

<hr/>

<div class="row bg-light">
    <div class="col-lg-6 col-xs-12 mb-2 offset-lg-1">
        <img src="/assets/images/portafolio/elektra/maquetacion.png" alt="Home" class="img-fluid">
    </div>
    <div class="col-lg-4 col-xs-12 mb-2">
        <img src="/assets/images/portafolio/elektra/landing.jpg" alt="Home" class="img-fluid mb-3">
        <img src="/assets/images/portafolio/elektra/guia.jpg" alt="Home" class="img-fluid">
    </div>
</div>
