<app-menu></app-menu>

<div class="row pt-5">
    <div class="col-lg-6 col-xs-12 pt-3 logo text-center">
        <img src="/assets/images/portafolio/quartz/logoGlobal.png" alt="">
    </div>
    <div class="col-lg-4 col-xs-12 text">
        <p>{{'globalQ.descripcion' | translate}}</p>
    </div>
</div>

<hr/>

<div class="row bg-light">

    <div class="col-lg-4 col-xs-12 mb-2">
        <img src="/assets/images/portafolio/quartz/home.png" alt="Home" class="img-fluid">
        <img src="/assets/images/portafolio/quartz/catalogo2.jpg" alt="Home" class="img-fluid">
    </div>

    <div class="col-lg-4 col-xs-12 mb-2">
        <img src="/assets/images/portafolio/quartz/all.jpg" alt="Home" class="img-fluid">
    </div>

    <div class="col-lg-4 col-xs-12 mb-2">
        <img src="/assets/images/portafolio/quartz/about.png" alt="Home" class="img-fluid">
        <img src="/assets/images/portafolio/quartz/contacto.jpg" alt="Home" class="img-fluid">
      
    </div>
</div>
<div class="row bg-light offset-lg-1">
    <div class="col-lg-6 col-xs-12 text-center mb-2">
        <img src="/assets/images/portafolio/quartz/catalogo.jpg" alt="Home" height="600">
    </div>
    

    <div class="col-lg-6 col-xs-12 d-flex text-center mb-2">
        <img src="/assets/images/portafolio/quartz/cuarzo.jpg" alt="Home" height="600">
    </div>
</div>