<app-menu></app-menu>

    <div class="row pt-5">
        <div class="col-lg-6 col-xs-12 pt-3 logo text-center">
            <img src="/assets/images/portafolio/temasili/logoTemasili.svg" alt="">
        </div>
        <div class="col-lg-4 col-xs-12 text">
            <p>{{'temasili.descripcion' | translate}}</p>
        </div>
    </div>

    <hr/>

    <div class="row bg-light text-center col-lg-12">
        
        <img src="/assets/images/portafolio/temasili/temasiliWeb.jpg" class="img-fluid" alt="">
        
    </div>