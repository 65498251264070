import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-elektra',
  templateUrl: './elektra.component.html',
  styleUrls: ['./elektra.component.css']
})
export class ElektraComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
